import React, { useContext, useEffect, useState } from 'react';
import ButtonCustom from '../../components/ButtonCustom';
import Header from '../../components/Header';
import OrderDetailItem from '../../components/OrderDetailItem';
import { AppContext } from '../../state/appContext';
import { ACTION_TYPES } from '../../state/actionTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { viewTransactionDetail } from '../../apis';
import { Collapse, message, Spin, Steps } from 'antd';
import { PAYMENT_METHODS, PAYMENT_STEPS } from '../../constants';
// import BelibitcoinInstant from 'belibitcoin-instant';
import { CopyOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { Nullable } from '../../@types/helper';
import { BELIBIT_INSTANT_STATUS } from '../../apis/enums';
import { AxiosError } from 'axios';
import { BelibitcoinInstant } from '../../helper/BelibitcoinInstant';
import { Conversion } from '../../apis/interfaces';
import { matchWhitelist } from '../../helper/utils';

const { Panel } = Collapse;

const { Step } = Steps;

const TransactionOrderNumber = (): React.JSX.Element => {
  const [expireTime, setExpireTime] = useState<Moment>(moment());
  const [currentTime, setCurrentTime] = useState<Moment>(moment());
  const { state, dispatch } = useContext(AppContext);
  const [paymentIcon, setPaymentIcon] = useState('');
  const [transactionStatus, setTransactionStatus] = useState<Nullable<BELIBIT_INSTANT_STATUS>>(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pStep, setPStep] = useState<typeof PAYMENT_STEPS[keyof typeof PAYMENT_STEPS]>([]);

  const navigate = useNavigate();

  const params = useParams<{ orderId?: string }>();

  const fetchData = async () => {
    try {
      setLoading(true);
      const orderId = params.orderId ?? state.orderId;
      const sourceId = state.sourceId ? state.sourceId : matchWhitelist(orderId) ? 'ntp' : 'telegram';
      const response = await viewTransactionDetail(orderId, sourceId);
      dispatch({
        type: ACTION_TYPES.UPDATE_ORDER,
        payload: {
          orderId: response.data.orderId,
          sourceId: response.data.sourceId,
          deposit: response.data.deposit,
          receive: response.data.receive
        }
      });

      dispatch({
        type: ACTION_TYPES.UPDATE_PAYMENT_METHOD,
        payload: PAYMENT_METHODS.find((m) => m.name === response.data.deposit.tagId)?.value as string,
      });

      dispatch({
        type: ACTION_TYPES.UPDATE_DESTINATION_ADDRESS,
        payload: response.data.receive.address as string,
      });

      dispatch({
        type: ACTION_TYPES.UPDATE_TXID,
        payload: response.data.txid
      });

      const depositMethod = PAYMENT_METHODS.find(e => response.data.deposit.tagId?.toLowerCase()?.indexOf(e.value.toLocaleLowerCase()) !== -1);
      if (depositMethod && depositMethod.icon) {
        setPaymentIcon(`/assets/icons/${depositMethod.icon}.svg`);
      }
      setExpireTime(moment(response.data.expiredAt));
      setTransactionStatus(response.data.status);
      setLoading(false);
    } catch (error) {
      setIsShowNotFound(true);
      message.error((error as AxiosError).response?.data?.error?.message ?? (error as Error).message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof state.paymentMethod !== 'undefined') {
      setPStep(PAYMENT_STEPS[state.paymentMethod as keyof typeof PAYMENT_STEPS]);
    }
  }, [state.paymentMethod]);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const onMessage = (event: MessageEvent<string>) => {
    // const data = JSON.parse(event.data)
    // setTransactionStatus(data.status)
    // setTxId(data.txId)
    // fetchData();
    try {
      const order: Conversion<BELIBIT_INSTANT_STATUS> = JSON.parse(event.data);
      dispatch({
        type: ACTION_TYPES.UPDATE_ORDER,
        payload: {
          orderId: order.orderId,
          sourceId: order.sourceId,
          deposit: order.deposit,
          receive: order.receive
        }
      });

      dispatch({
        type: ACTION_TYPES.UPDATE_DESTINATION_ADDRESS,
        payload: order.receive.address as string,
      });

      dispatch({
        type: ACTION_TYPES.UPDATE_TXID,
        payload: order.txid
      });

      const depositMethod = PAYMENT_METHODS.find(e => order.deposit.tagId?.toLowerCase()?.indexOf(e.value.toLocaleLowerCase()) !== -1);
      if (depositMethod && depositMethod.icon) {
        setPaymentIcon(`/assets/icons/${depositMethod.icon}.svg`);
      }
      setExpireTime(moment(order.expiredAt));
      setTransactionStatus(order.status);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setIsShowNotFound(true);
      message.error(`websocket error: ${(error as Error).message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    const orderId = state.orderId;
    const sourceId = state.sourceId ? state.sourceId : matchWhitelist(orderId) ? 'ntp' : 'telegram';
    const belibitInstant = new BelibitcoinInstant({ sourceId });
    belibitInstant.listenStatus(state.orderId, onMessage);
  }, []);

  useEffect(() => {
    if (transactionStatus === BELIBIT_INSTANT_STATUS.COMPLETED || transactionStatus === BELIBIT_INSTANT_STATUS.DELIVERED) {
      navigate('/transaction/completed');
    }
  }, [transactionStatus]);

  const checkStatus = async () => {
    const sourceId = state.sourceId ? state.sourceId : matchWhitelist(state.orderId) ? 'ntp' : 'telegram';
    const response = await viewTransactionDetail(state.orderId, sourceId);
    if (response.data.status === BELIBIT_INSTANT_STATUS.COMPLETED || transactionStatus === BELIBIT_INSTANT_STATUS.DELIVERED) {
      dispatch({
        type: ACTION_TYPES.UPDATE_ORDER_ID, payload: response.data.txid as string,
      });
      navigate('/transaction/completed');
    } else {
      message.info(response.data.status);
    }
  };

  const convertHMS = (sec: number) => {
    const hours = Math.floor(sec / 3600); // get hours
    const minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    const seconds = Math.floor(sec - (hours * 3600) - (minutes * 60)); //  get seconds
    let hourString = hours.toString();
    let minuteString = minutes.toString();
    let secondString = seconds.toString();
    if (hours < 10) { hourString = `0${hourString}`; }
    if (minutes < 10) { minuteString = `0${minuteString}`; }
    if (seconds < 10) { secondString = `0${seconds}`; }
    return `${hourString}:${minuteString}:${secondString}`;
  };

  const handleClickOrderUlang = () => {
    navigate('/buy-crypto');
  };

  const handleBackHomePage = () => {
    navigate('/buy-crypto');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.host}/transaction/ordernumber/${state?.orderId}`);
    message.success('copied!');
  };

  const handleMandiriVA = (
    bank: typeof PAYMENT_METHODS[number]['value'],
    va?: Nullable<string>,
  ): Nullable<string> | undefined => {
    if (!va) {
      return va;
    }
    if (bank === 'BERSAMA' || bank === 'MANDIRI') {
      const isIPay = !va.startsWith('89039') && !va.startsWith('1200');
      if (isIPay) {
        return `88017${va}`;
      }
    }
    return va;
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="transaction-order-number">
          {/** @description NOT FOUND */}
          {isShowNotFound && (
            <div className='order-expired'>
              <Steps current={1} labelPlacement="vertical" responsive={false}>
                <Step title="Ordered" status="wait" />
                <Step title="Processing" status="wait" />
                <Step title="Completed" status="wait" />
              </Steps>

              <div className='order-expired__content'>
                <img className='order-expired__img' alt='order-expired' src='/assets/icons/order-expired.svg' />
                <span className='order-expired__title'>Order not found</span>
              </div>
              <ButtonCustom onClick={handleBackHomePage}>Back to home</ButtonCustom>
            </div>)}

          {/** @description EXPIRED  */}
          {expireTime.unix() - currentTime.unix() <= 0 && transactionStatus === BELIBIT_INSTANT_STATUS.WAITING_DEPOSIT && <div className='order-expired'>
            <Steps current={1} labelPlacement="vertical" responsive={false}>
              <Step title="Ordered" status="wait" />
              <Step title="Processing" status="wait" />
              <Step title="Completed" status="wait" />
            </Steps>

            <div className='order-expired__content'>
              <img className='order-expired__img' alt='order-expired' src='/assets/icons/order-expired.svg' />
              <span className='order-expired__title'>Order has expired</span>
              <span className='order-expired__description'>Please reorder to continue the process</span>
            </div>
            <ButtonCustom onClick={handleBackHomePage}>Buy again</ButtonCustom>
          </div>}

          {/** @description WAITING_DEPOSIT  */}
          {!isShowNotFound && transactionStatus === BELIBIT_INSTANT_STATUS.WAITING_DEPOSIT && expireTime.unix() - currentTime.unix() > 0 && <Header title='Finish your payment in' onPreviousPage={() => navigate(-1)} />}
          {expireTime.unix() - currentTime.unix() > 0 && transactionStatus === BELIBIT_INSTANT_STATUS.WAITING_DEPOSIT && <>
            <div className='time-out'>{expireTime.unix() - currentTime.unix() > 0 ? convertHMS(expireTime.unix() - currentTime.unix()) : 'The transaction expired'}</div>

            <div className='general-information'>
              <span className='name-information'>Batas akhir pembayaran</span>
              <span className='time-information'>{expireTime.format('dddd, MMMM Do YYYY HH:mm')}</span>
            </div>

            <div className='bank-information'>
              <div className='bank-infomation--img'>
                {paymentIcon && <img className='bank-infomation__img' style={{ height: '35px' }} alt='bank-information' src={paymentIcon} />}
              </div>
              <div className="bank-information--title">Nomor Virtual Account</div>
              <div className="bank-information--content">
                <span>{handleMandiriVA(state.paymentMethod, state.deposit?.address)}</span>
                <span style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(state.deposit?.address as string); message.success('copied!'); }}>Salin <CopyOutlined /></span>
              </div>
              <div className="bank-information--title">Nama</div>
              <div className="bank-information--content">
                <span>{state.deposit?.tag}</span>
                <span></span>
              </div>
              <div className="bank-information--title">Total pembayaran</div>
              <div className="bank-information--content">
                <span>{state.deposit?.currency} {parseFloat(state.deposit?.amount as string).toLocaleString()}</span>
                <span style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(state.deposit?.amount as string); message.success('copied!'); }}>Salin <CopyOutlined /></span>
              </div>
            </div>

            <div className='method-payment'>
              <div className="method-payment--title">Cara melakukan pembayaran</div>
              <Collapse accordion expandIconPosition='end'>
                {pStep.map((step, index) => {
                  return (<Panel
                    header={<OrderDetailItem
                      leftText={<div>
                        <img className='method-payment--icon' alt='bank-information--atm' src={`/assets/images/${step.assetName}`} />
                        <span>{step.stepName}</span>
                      </div>}
                    />}
                    key={`${index + 1}`}>
                    {step.stepDetails.map((detail, detailIdx) => {
                      return (<p
                        key={`${detailIdx}`}
                        style={{ boxDecorationBreak: 'clone', WebkitBoxDecorationBreak: 'clone', }}>
                        {`${detailIdx + 1}. ${detail}`}
                      </p>);
                    })}
                  </Panel>);
                })}
              </Collapse>
            </div>

            <ButtonCustom onClick={checkStatus}>Cek status pembayaran</ButtonCustom>
            <ButtonCustom className='button-custom--white' onClick={handleClickOrderUlang}>Order Ulang</ButtonCustom>
          </>}

          {(transactionStatus === BELIBIT_INSTANT_STATUS.PROCESSING || transactionStatus === BELIBIT_INSTANT_STATUS.CONVERTED) && <div className='order-processing'>
            <Steps current={1} labelPlacement="vertical" responsive={false}>
              <Step title="Ordered" status="process" />
              <Step title="Processing" />
              <Step title="Completed" />
            </Steps>
            <div className='order-processing__content'>
              <img className='order-processing__img' alt='order-processing' src='/assets/icons/order-processing.svg' />
              <span className='order-processing__title'>Processing transaction</span>
              <span className='order-processing__description'>Payment received. The system is processing and sending to the destination address.</span>

              <div className='order-processing__disclaimer'>
                <span>You may close this at any time. If there are any issues, we'll contact you via WhatsApp.</span>
              </div>
            </div>
            <ButtonCustom onClick={handleCopyLink}>Copy URL</ButtonCustom>
            <ButtonCustom className='button-custom--white' onClick={handleBackHomePage}>Back to home</ButtonCustom>
          </div>}
        </div>
      </Spin>

      <style jsx>{`
      .transaction-order-number {
        padding: 24px;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
      }

      .time-out {
        text-align: center;
        margin-top: 16px;
        color: #F79E1B;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
      }

      .general-information {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 8px;
        font-size: 14px;
        line-height: 21px;
      }

      .name-information {
        color: #777777;
        font-weight: 400;
      }

      .time-information {
        color: #000000;
        font-weight: 500;
      }

      .bank-information {
        display: flex;
        flex-direction: column;
        padding: 16px;
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        margin-top: 24px;
        font-size: 12px;
        line-height: 18px;
      }

      .bank-infomation--img {
        text-align: center;
      }

      .bank-information--title {
        color: #777777;
        font-weight: 400;
        margin-bottom: 12px;
        margin-top: 16px;
      }

      .bank-information--content {
        font-weight: 600;
        display: flex;
        justify-content: space-between;
      }

      .bank-information--content > span:last-child {
        color: #3385FF;
      }

      .method-payment {
        margin-top: 25px;
        margin-bottom: 8px;
      }

      .button-custom--white {
        background-color: #fff;
        color: #3385FF;
        border: none;
        box-shadow: none;
      }

      .button-custom--white:hover, .button-custom--white:focus, .button-custom--white:active, .button-custom--white:focus {
        background-color: #fff;
        color: #3385FF;
        border: none;
        box-shadow: none;
      }

      .method-payment--icon {
        margin-right: 8px;
      }

      .ant-collapse, .order-detail-item {
        border: none;
      }

      .ant-collapse-header {
        padding: 0 !important;
        background: #fff;
      }

      .ant-collapse-item {
        background: #fff;
      }

      .ant-collapse-content-active {
        border-top: none;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
        font-weight: 400;
        font-size: 11px;
        line-height: 10px;
        color: #777777;
      }

      .ant-empty {
        margin-top: 24px;
      }

      .order-expired, .order-processing {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .order-expired__content, .order-processing__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        flex-grow: 1;
      }

      .order-expired__img, .order-processing__img {
        width: 120px;
        height: 120px;

        margin-top: 24px;
      }

      .order-expired__title, .order-processing__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #000000;

        margin-top: 24px;
      }

      .order-expired__description, .order-processing__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #777777;
        margin-top: 8px;
      }

      .ant-spin-nested-loading, .ant-spin-container {
        height: 100%;
      }

      .ant-steps {
        margin-top: 24px;
      }

      .ant-steps-item-process .ant-steps-item-icon {
        background: #29D664 !important;
        border-color: #29D664 !important;
      }

      .ant-steps-item-wait .ant-steps-item-icon {
        background: #EEEEEE !important;
        border-color: #EEEEEE !important;
      }

      .ant-steps-item-process .ant-steps-item-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #777777 !important;
      }

      .ant-steps-item-wait .ant-steps-item-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #EEEEEE !important;
      }

      .order-processing__content {
        display: flex;
        flex-direction: column;
      }

      .order-processing__disclaimer {
        background: rgba(255, 217, 159, 0.2);
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;

        color: #777777;
        padding: 16px;
        margin-top: 24px;
      }
    `}
      </style>
    </>
  );
};

export default TransactionOrderNumber;
