import { PaymentSteps } from './interfaces';

const bcaPaymentSteps: PaymentSteps[] = [
  {
    stepName: 'ATM',
    stepDetails: [
      'Masukkan Kartu ATM BCA & PIN',
      'Pilih menu Transaksi Lainnya > Transfer > ke Rekening BCA Virtual Account',
      'Masukkan nomor Virtual Account yang tertera pada halaman ini',
      'Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti No VA, Nama, Perus/Produk dan Total Tagihan',
      'Masukkan Jumlah Transfer sesuai dengan Total Tagihan',
      'Ikuti instruksi untuk menyelesaikan transaksi',
      'Simpan struk transaksi sebagai bukti pembayaran',
    ],
    assetName: 'atm.png',
  },
  {
    stepName: 'Mobile Banking',
    stepDetails: [
      'Lakukan log in pada aplikasi BCA Mobile',
      'Pilih menu m-BCA, kemudian masukkan kode akses m-BCA',
      'Pilih m-Transfer > BCA Virtual Account',
      'Masukkan nomor Virtual Account yang tertera pada halaman ini',
      'Masukkan pin m-BCA',
      'Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran',
    ],
    assetName: 'mobile-banking.png',
  },
  {
    stepName: 'Internet Banking',
    stepDetails: [
      'Login pada alamat Internet Banking BCA (https://klikbca.com)',
      'Pilih menu Pembayaran Tagihan > Pembayaran > BCA Virtual Account',
      'Masukkan nomor Virtual Account yang tertera pada halaman ini',
      'Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti Nomor BCA Virtual Account, Nama Pelanggan dan Jumlah Pembayaran',
      'Masukkan password dan mToken',
      'Cetak/simpan struk pembayaran BCA Virtual Account sebagai bukti pembayaran',
    ],
    assetName: 'internet-banking.png',
  },
  {
    stepName: 'Cabang',
    stepDetails: [
      'Datang ke cabang terdekat',
      'Utarakan maksud ke security.',
      'Security akan mengarahkan anda langkah selanjutnya.',
    ],
    assetName: 'cabang.png',
  }
];

const mandiriPaymentSteps: PaymentSteps[] = [
  {
    stepName: 'ATM',
    stepDetails: [
      'Masukkan kartu ATM dan PIN',
      'Pilih menu "Bayar/Beli"',
      'Pilih menu "Lainnya", hingga menemukan menu "Multipayment"',
      'Masukkan kode Perusahaan "88017", lalu pilih "Benar".',
      'Masukkan nomor Virtual Account yang tertera pada halaman ini',
      'Masukkan nominal yang tertera pada halaman ini, kemudian pilih "Benar".',
      'Kemudian muncul menu konfirmasi, pilih nomor 1 dan pilih "Ya".',
      'Akan muncul konfirmasi pembayaran, lalu pilih tombol "Ya"',
      'Simpan struk sebagai bukti pembayaran Anda',
    ],
    assetName: 'atm.png',
  },
  {
    stepName: 'Internet Banking',
    stepDetails: [
      'Login Livin\' By Mandiri dengan memasukkan Username dan Password',
      'Pilih menu "Pembayaran"',
      'Pilih menu "Multipayment"',
      'Di kolom "Penyedia Jasa" pilih I-Pay',
      'Di kolom "No.Ref/Kode Mitra" masukkan nomor Virtual Account yang tertera pada halaman ini',
      'Di kolom nominal masukkan nominal yang tertera pada halaman ini',
      'Setelah muncul tagihan, pilih Konfirmasi',
      'Masukkan PIN / Challenge Code Token',
      'Transaksi selesai, simpan bukti bayar Anda',
    ],
    assetName: 'internet-banking.png',
  }
];

const permataPaymentSteps: PaymentSteps[] = [
  {
    stepName: 'ATM',
    stepDetails: [
      'Masukkan kartu ATM anda.',
      'Pilih bahasa.',
      'Masukkan PIN ATM.',
      'Pilih menu "Transaksi Lainnya". Lalu pilih menu "Transfer" dan kemudian pilih menu "Rek NSB Lain Permata".',
      'Masukkan nomor virtual account (contoh: 8461011230000001) dan pilih "Benar".',
      'Kemudian masukkan nominal transaksi sesuai dengan invoice yang ditagihkan, lalu pilih "Benar".',
      'Lalu pilih rekening Anda. Tunggu sebentar hingga muncul konfirmasi pembayaran. Kemudian pilih "Ya".',
      'Pembayaran berhasil.',
    ],
    assetName: 'atm.png',
  },
  {
    stepName: 'Mobile Banking',
    stepDetails: [
      'Setelah login, pilih menu "Pembayaran Tagihan" dan pilih menu "Virtual Account".',
      'Pilih "Tagihan Anda" dan pilih "Daftar Tagihan Baru".',
      'Masukkan nomor virtual account (contoh: 8461011230000001) lalu pilih "Konfirmasi".',
      'Masukkan "Nama Pengingat" setelah itu klik "Lanjut". Setelah itu pilih "Konfirmasi".',
      'Masukkan nominal tagihan sesuai invoice, kemudian pilih "Konfirmasi".',
      'Masukkan "Response Code" lalu pilih "Konfirmasi".',
      'Proses transfer selesai.',
    ],
    assetName: 'mobile-banking.png',
  },
  {
    stepName: 'Internet Banking',
    stepDetails: [
      'Akses https://new.permatanet.com/permatanet/retail/logon',
      'Setelah login, pilih menu "Pembayaran".',
      'Lalu pilih sub menu "Pembayaran Tagihan" dan pilih "Virtual Account".',
      'Pilih rekening anda sebagai sumber dana, lalu masukkan nomor virtual account (contoh: 8461011230000001) lalu pilih "Lanjut".',
      'Masukkan nominal tagihan pada bagian "Total Pembayaran" sesuai dengan invoice, lalu pilih "Submit".',
      'Tunggu sebentar hingga memperoleh SMS notifikasi yang berisi kode. Lalu masukkan kode tersebut.',
      'Proses transfer selesai.',
    ],
    assetName: 'internet-banking.png',
  },
];

// const maybankPaymentSteps: PaymentSteps[] = [
//   {
//     stepName: 'ATM',
//     stepDetails: [
//       'Masukkan Kartu Anda.',
//       'Pilih Bahasa.',
//       'Masukkan PIN ATM Anda.',
//       'Pilih "Menu Pembayaran".',
//       'Pilih "Virtual Account".',
//       'Masukkan nomor Virtual Account Anda (contoh: 7893901230000001), dan jumlah pembayaran yang akan dibayarkan.',
//       'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
//       'Konfirmasi, apabila telah sesuai, lanjutkan transaksi.',
//       'Transaksi Anda telah selesai.',
//     ],
//     assetName: 'atm.png',
//   },
//   {
//     stepName: 'Mobile Banking',
//     stepDetails: [
//       'Akses Maybank Mobile Banking dari handphone kemudian masukkan user ID dan password.',
//       'Pilih menu "Transfer".',
//       'Pilih menu "Maybank Virtual Account"',
//       'Pilih sumber dana lalu, masukkan nomor Virtual Account Anda (contoh: 7893901230000001) dan nominal yang akan dibayar.',
//       'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
//       'Klik tombol request TAC, tunggu hingga mendapat kode TAC yang dikirim lewat SMS.',
//       'Masukkan kode TAC tersebut ke kolom kode TAC. Lalu klik konfirmasi.',
//       'Pembayaran Anda Telah Berhasil.',
//     ],
//     assetName: 'mobile-banking.png',
//   },
//   {
//     stepName: 'Internet Banking',
//     stepDetails: [
//       'Akses https://m2u.maybank.co.id/common/Login.do',
//       'Masukkan User ID dan password.',
//       'Klik menu Transfer, lalu pilih "Maybank Virtual Account".',
//       'Pilih sumber dana, kemudian masukan nomor Virtual Account Anda (contoh: 7893901230000001) dan nominal yang harus dibayarkan.',
//       'Lalu akan muncul layar konfirmasi pembayaran. Klik tombol request TAC, tunggu hingga mendapat kode TAX yang dikirim lewat SMS',
//       'Masukkan kode TAC tersebut ke kolom kode TAC. Lalu klik konfirmasi.',
//       'Pembayaran Anda telah berhasil.'
//     ],
//     assetName: 'internet-banking.png',
//   },
// ];

const bersamaPaymentSteps: PaymentSteps[] = [
  {
    stepName: 'ATM',
    stepDetails: [
      'Pilih Bahasa.',
      'Masukkan PIN.',
      'Pilih Transaksi Lainnya > Transfer > Ke Rekening Bank lain ATM Bersama/Link.',
      'Masukkan Kode Bank Permata (013) + 16 digit nomor Virtual Account yang tertera pada halaman ini.',
      'Masukkan jumlah nominal yang tertera pada halaman ini.',
      'Isi atau kosongkan nomor referensi transfer kemudian tekan "Benar".',
      'Transaksi selesai, simpan bukti pembayaran Anda.',
    ],
    assetName: 'atm.png',
  },
];

export const PAYMENT_STEPS = {
  BCA: bcaPaymentSteps,
  MANDIRI: mandiriPaymentSteps,
  BERSAMA: mandiriPaymentSteps,
  PERMATA: permataPaymentSteps,
};